<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <!-- Botón para escritorio -->
      <MenuButton class="md:inline-flex justify-center w-full shadow-sm px-1 py-1 text-sm
      font-medium text-white hover:bg-white-50 hidden">
        <div class="flex my-1">
          <!--          <BellIcon class="mr-1 ml-2 my-2 w-8 h-8" aria-hidden="true"/>-->
          <img alt="loading" src="@/assets/events.gif" class="mr-1 ml-2 my-0 w-12 h-12">
          <div class="flex flex-col">
            <div class="bg-red-500 rounded-full relative -left-3 text-white p-1 px-2 text-xs">
              {{ total }}
            </div>
          </div>
        </div>
        <ChevronDownIcon class="m-1 h-5 w-5" aria-hidden="true"/>
      </MenuButton>

      <!-- Botón para móviles -->
      <MenuButton class="inline-flex justify-center w-full shadow-sm px-1 pt-2 text-sm font-medium
      text-white hover:bg-white-50 md:hidden">
        <div class="flex">
          <!--          <BellIcon class="mr-1 ml-2 my-2 w-8 h-8" aria-hidden="true"/>-->
          <img alt="loading" src="@/assets/events.gif" class="mr-1 ml-2 my-2 w-12 h-12">
        </div>
      </MenuButton>
    </div>

    <!-- Contenido del menú -->
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute left-0 md:mt-2 md:w-96 w-96
      max-h-[400px] z-20 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
      company-name flex flex-col flex-grow bg-white">
        <div class="w-full bg-transparent rounded-lg shadow-lg">
          <div class="bg-header text-white p-3 flex justify-between items-center rounded-t-lg">
            <div class="flex items-center space-x-2">
              <button @click="prevDay" class="bg-transparent text-white px-1 py-2 rounded-md">
                <ChevronLeftIcon class="h-6 w-6 text-white"/>
              </button>
              <button @click="nextDay" class="bg-transparent text-white px-1 py-2 rounded-md">
                <ChevronRightIcon class="h-6 w-6 text-white"/>
              </button>
              <span class="font-bold">{{ formattedDate }}</span>
            </div>
            <button class="bg-transparent text-white p-2 rounded-md" @click="goToCalendar()">
              <CalendarIcon class="h-6 w-6 text-white"/>
            </button>
          </div>

          <div class="flex-1 p-2 overflow-auto max-h-96 bg-white">
            <template v-if="isLoading">
              <div class="flex flex-col items-center justify-center">
                <img alt="loading" src="@/assets/loading.gif" class="w-20">
                <p>Cargando..</p>
              </div>
            </template>
            <template v-else>
              <template v-if="total > 0">
                <div v-for="(event, index) in events" :key="index"
                     class="flex items-center justify-between p-3 border rounded-lg my-1
                 border-gray-800"
                     :class="{'bg-gray-200': isPastEvent(event.start_date)}">
                  <!-- Hora -->
                  <span class="font-medium text-sm text-gray-900">{{ event.time }}</span>

                  <!-- Línea separadora -->
                  <span class="h-6 border-l border-gray-400 mx-2"></span>

                  <!-- Contenido del evento -->
                  <div class="flex items-center gap-2 flex-1">
                    <div class="p-2 rounded-full m-1"
                         :style="{ backgroundColor: event.color }">
                    </div>
                    <div class="flex flex-col">
                      <span class="font-bold text-gray-900">{{ event.title }}</span>
                      <span v-if="event.user" class="text-gray-900 text-sm">
                        {{ event.user.name }}
                      </span>
                    </div>
                  </div>
                  <!-- Ícono de ojo -->
                  <button class="text-gray-600" @click="openLead(event)">
                    <EyeIcon class="h-6 w-6 "/>
                  </button>
                </div>
              </template>
              <template v-if="total < 1">
                <p class="text-gray-900 text-center">No tienes recordatorios</p>
              </template>
            </template>
          </div>
          <div class="w-full relative bottom-1" v-if="nextPageUrl">
            <div class="m-2 flex rounded-lg cursor-pointer text-white place-content-center p-2"
                 style="background-image: linear-gradient(90deg,#00bfbc,#009db2)"
                 @click="loadMoreEvents()" @keydown="loadMoreEvents()">
              {{ isLoadingMore ? 'Cargando....' : 'ver más' }}
            </div>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
import {
  ChevronDownIcon,
  CalendarIcon,
  EyeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/vue/solid';

export default {
  name: 'EventsDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    EyeIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CalendarIcon,
  },
  data() {
    return {
      currentDate: new Date(),
      isLoadingMore: false,
      isLoading: false,
    };
  },
  methods: {
    prevDay() {
      this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() - 1));
      this.getEvents();
    },
    nextDay() {
      this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
      this.getEvents();
    },
    isPastEvent(startDate) {
      const now = new Date();
      console.log('now', now);
      const eventDate = new Date(startDate);
      console.log('eventDate', eventDate);
      console.log('eventDate < now', eventDate < now);
      return eventDate < now; // Devuelve true si el evento ya pasó
    },
    goToCalendar() {
      const url = this.$router.resolve({ path: '/calendar' }).href;
      window.open(url, '_blank');
    },
    getEvents() {
      // TODO: send pagination
      this.isLoading = true;
      const payload = {
        company: this.activeCompany.uuid,
        start: this.currentDate,
        end: this.currentDate,
      };
      this.$store.dispatch('events', payload)
        .then((response) => {
          this.isLoading = false;
          console.log('respuesta events', response);
        }, (error) => {
          console.log('error events', error);
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error events', error);
        });
    },
    loadMoreEvents() {
      if (this.isLoadingMore) return;
      const payload = {
        company: this.activeCompany.uuid,
        url: this.nextPageUrl,
        start: this.currentDate,
        end: this.currentDate,
      };
      this.isLoadingMore = true;
      this.$store.dispatch('nextPageEvents', payload)
        .then((response) => {
          console.log('respuesta nextPageEvents', response);
          this.isLoadingMore = false;
        }, (error) => {
          this.isLoadingMore = false;
          console.log('error nextPageEvents', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMore = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error nextPageEvents', error);
        });
    },
    openLead(event) {
      this.$emit('open-lead', event.lead_uuid);
    },
  },
  computed: {
    events() {
      return this.$store.state.events || [];
    },
    total() {
      return this.$store.state.total_ev;
    },
    nextPageUrl() {
      return this.$store.state.next_page_url_ev;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    formattedDate() {
      return this.currentDate.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    },
  },
  watch: {},
  created() {
  },
};
</script>

<style>
.company-name {
  background: #2a323c;
  color: #fff;
}

.text-company {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-company span {
  color: #FFCF01;
}

button:focus {
  outline: none !important;
}

/* Scroll */
.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #8ad2e8;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #2a323c;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
}

.logo-company {
  margin-top: -5px;
}

.text-mobile {
  position: absolute;
  left: 21px;
  top: 7px;
  font-size: 9px;
  padding: 2px;
  height: 20px;
  width: 21px;
}

@media (max-width: 600px) {
  .area-message {
    left: -115px;
  }

  .area-notification {
    left: -71px;
  }
}

.bg-header {
  background-color: #353E4B;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100.bg-sky-400.border-sky-400.text-white {
  color: #ffffff;
  background-color: #2A6F7F !important;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100 {
  color: #3a3838;
  background-color: #2A6F7F !important;
  border-color: #EAF2F9;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}
</style>
