import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import ReportsView from '@/views/ReportsView.vue';
import CalendarView from '@/views/CalendarView.vue';
import ChatView from '@/views/ChatView.vue';
import LeadsView from '@/views/LeadsView.vue';
import DatesView from '@/views/DatesView.vue';
import ContactsView from '@/views/ContactsView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/reportes',
    name: 'reportes',
    component: ReportsView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/chat',
    name: 'chat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ChatView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/leads',
    name: 'leads',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LeadsView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/dates',
    name: 'dates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DatesView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ContactsView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoginView,
    meta: {
      authRequired: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach((to, from, next) => {
  const userInfo = localStorage.getItem('userInfo');
  // const token = localStorage.getItem('accessToken');
  if (to.meta.authRequired) {
    if (!userInfo) {
      router.push({ path: '/login', query: { to: to.path } });
    } else {
      // store.commit('SET_BEARER', localStorage.getItem('accessToken'));
      // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (userInfo) {
      if (to.name === 'home' || to.name === 'login') {
        console.log('estamos en home ');
        router.push({ path: '/leads' });
      }
    }
  }
  return next();
});

export default router;
